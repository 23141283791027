import { GetTaskQuery, TaskInput } from "@/shared/types/schema.types";

export function decodeTask(taskData: GetTaskQuery["task"]): TaskInput {
  return {
    ..._.pick(taskData, ["name", "description"]),
    coexecutorId: taskData.coexecutor?.id,
    executorId: taskData.executor?.id || "",
    attachmentInputs: taskData.descriptionAttachments,
    priorityCode: taskData.priority.code,
    parentTaskId: taskData.parentTask?.id,
    deadline: taskData.deadline,
  };
}
