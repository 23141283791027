






























import { useMutation } from "@vue/apollo-composable";
import { computed, defineComponent, ref } from "@vue/composition-api";

import BackLink from "@/app/components/Base/BackLink.vue";
import TaskForm from "@/app/components/Task/TaskForm.vue";
import router from "@/app/core/router";
import { encodeAttachmentInput } from "@/models/AttachmentInput";
import { decodeTask } from "@/models/Task";
import { TASK_DATA } from "@/shared/constants/components/Task/Task";
import { useRoute } from "@/shared/hooks";
import {  useTask } from "@/shared/hooks/fetchers";
import { useNotification } from "@/shared/hooks/notification";
import { TaskInputExt } from "@/shared/types/components/Task/Task";
import { TaskFormTasks, Users } from "@/shared/types/components/Task/TaskForm";
import { TaskInput, UpdateTaskDocument } from "@/shared/types/schema.types";
import { UploadSingleFileDocument } from "@/shared/types/schema.upload.types";

export default defineComponent({
  components: {
    TaskForm,
    BackLink, 
  },
  setup() {
    const { mutate: editTaskMutation, onDone } = useMutation(UpdateTaskDocument);
    const { mutate: uploadMutation } = useMutation(UploadSingleFileDocument, { clientId: "upload" });
    const { errNotification } = useNotification();
    const route = useRoute();
    const taskId = route.params.taskId;
    const projectId = route.params.id;
    const { onResult, onError, loading } = useTask({ id: taskId });
    const taskData = ref<TaskInputExt>(_.cloneDeep(TASK_DATA));
    const initialUsers = ref<Users>([]);
    const initialTasks = ref<TaskFormTasks>([]);

    onResult((res) => {
      initialUsers.value = _.compact([res.data.task.coexecutor, res.data.task.executor]);
      initialTasks.value = _.compact([res.data.task.parentTask] as TaskFormTasks);
      taskData.value = decodeTask(res.data.task) as TaskInputExt;
    });

    const taskListLink = computed(() => {
      return projectId? `/projects/${projectId}` : `/`;
    });

    const backLink = computed(() => projectId? `/projects/${projectId}/tasks/${taskId}` : `/tasks/${taskId}`);

    const isDisabled = computed(
      () =>
        !Object.keys(
          _.pick(taskData.value, [
            "deadline",
            "name",
          ]),
        ).every((el) => !!taskData.value && taskData.value[el as keyof TaskInput]),
    );
    async function editTask() {
      await editTaskMutation({
        taskInput: {
          ...taskData.value,
          attachmentInputs: encodeAttachmentInput(taskData.value.attachmentInputs),
        },
        id: taskId, 
      });
    }

    onDone(() => {
      return router && router.push(taskListLink.value);
    });

    onError((err) => {
      errNotification(err);
    });

    async function uploadFiles(file: File) {
      try {
        await uploadMutation({ upload: file });
      }
      catch(err) {
        console.error(err);
        errNotification(err);
      }
    }

    return {
      backLink,
      editTask,
      isDisabled,
      taskData,
      loading,
      projectId,
      initialUsers,
      initialTasks,
      uploadFiles,
    };
  },

});
